<template>
  <div class="page">

    <img class="logo" src="../../assets/images/logo_ytss_rectangle.png" alt="">
    <div class="text">放心食材，扫码溯源</div>
    <div class="inputContainer">
      <van-field
        style="padding: 10px;border-radius: 8px;width: 300px;"
        v-model="traceCode"
        placeholder="请输入条形码"
      />
      <!-- <div class="openSearchButton" @click="openScan">扫一扫</div> -->
    </div>
    <div class="search" @click="openSearch">查   询</div>

    <div class="use-dir">
      <div>使用说明：</div>
      <div>手动录入条码下方数字后点击“查询”</div>
    </div>
    
    <div class="image-div">
      <img @click="imgPlus()" class="img" src="../../assets/images/shipping-bill.png" alt="">
    </div>

  </div>
</template>

<script>
import Vue from "vue";

import {
  Toast,
  Image as VanImage,
  Empty,
  Dialog,
  List,
  Loading,
  Field,
} from "vant";
import { post } from "../../utils/http";
import { ImagePreview  } from "vant";
import axios from 'axios'
Vue.use(Toast)
  .use(VanImage)
  .use(Empty)
  .use(Dialog)
  .use(List)
  .use(Loading)
  .use(Field)
  .use(ImagePreview);
export default {
  name: "home",
  data() {
    return {
      wx:null,
      traceCode: "",
      codeReader: null,
        tipShow: false,  // 是否展示提示
        tipMsg: '',  // 提示文本内容
        scanText: '',  // 扫码结果文本内容
        url:require("../../assets/images/shipping-bill.png")
    };
  },

  
  mounted() {
    this.wx = Vue.prototype.wx
  
    // const url ="http://172.16.1.34:8091/api/public/v2/wechat/getSignature"
    const url ="https://test.srm.zhongshanyun.cn/srm/api/public/v2/wechat/getSignature"
    

    // let params = {"url": encodeURIComponent(location.href.split('#')[0])};
    //http://test.trace-h5.zhongshanyun.cn/ctSearch
    // alert(location.href.split('#')[0])
    // let params = {"url": encodeURIComponent(location.href.split('#')[0])};
    //   post(url, params, false)
    //     .then((res) => {
    //      //isConsistent:1 是销售区域匹配，0是不匹配
    //       console.log(res)
    //       this.wx.config({
    //         debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //         appId:"wx6ad435862f87a348", // 必填，公众号的唯一标识
    //         timestamp:  res.data.timestamp, // 必填，生成签名的时间戳
    //         nonceStr: res.data.noncestr, // 必填，生成签名的随机串
    //         signature: res.data.signature,// 必填，签名
    //         jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
    //       });

    //       this.wx.ready(() => {
    //         console.log("验证成功")
    //       });

    //       this.wx.error(res => {
    //         console.log("验证失败")
    //         console.log(res);
    //       })

    //     })
    //     .catch((err) => {
          
    //     })
    //     .finally(() => {

    //     });
    this.getToken()
    
  },
  destroyed() {},

  methods: {
    imgPlus(){
      ImagePreview({
        images: [this.url],
        closeable: true,
      });
    },
    async getToken(){

     
      // const url = "https://api.weixin.qq.com/cgi-bin/stable_token";
      // let data = {
      //       "grant_type": "client_credential",
      //       "appid": "wx6ad435862f87a348",
      //       "secret": "517534a03bad88c6e0ec4d3306b62403"
      //   };
      // post(url, data, false)
      //   .then((res) => {
           
      //     console.log(res)
      //   });

      try {


        const response = await axios.get(`https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=wx6ad435862f87a348&secret=517534a03bad88c6e0ec4d3306b62403`);


        const accessToken = response;


        console.log('Access Token:', accessToken);


        return accessToken;


      } catch (error) {


       console.error('Error getting access token:', error);


      }



    },
    openSearch(){
      console.log( this.traceCode)

      if(  this.traceCode.trim() ==""){
        alert("条形码不能为空")
        return
      }
      let hasLetter = /[A-Za-z]/.test(this.traceCode);
      var haschina = /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/.test(this.traceCode);
      if (hasLetter || haschina) {
        alert("只能输入数字+特殊字符");
        return
      } 

      if(this.traceCode.indexOf("_") == -1){
        alert("条形码格式不正确");
        return
      }
      let arr = this.traceCode.split('_')
      let isnum =  /^\d+$/.test(arr[0]);
      let isnum2 =  /^\d+$/.test(arr[1]);

      if(arr[0].length !=18 || !isnum || !isnum2){
        alert("条形码格式不正确");
        return
      }

      const url =
        "/api/public/v2/business/order/item/{code}/exist".replace(
          "{code}",
          this.traceCode
      );
      post(url, null, false)
        .then((res) => {
           // 0表示不存在，输入条码有误 | 1表示存在，可以跳转
          console.log(res)
          if(res.data == 0){
            alert("条码异常，请重新检查")
          }else{

            this.$router.push({path: '/ct?traceCode='+this.traceCode});
          }
          
        })
        .catch((err) => {
          
        })
        .finally(() => {

        });

    },
    async openScan() {  // 初始化摄像头
       

      

    },
   
  },
};
</script>


<style lang="less" scoped>
.page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
.logo{

  width: 70%;
}
.text {
  font-size: 18px;
  // height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.inputContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
  .openSearchButton {
    width: 80px;
    height: 100%;
    border: 1px solid #000;
  }
}
.search {

  width: 290px;
  height: 40px;
  background-color: #34ac40;
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 8px;
  margin-top: 20px;
  letter-spacing: 0.4em;
}
.use-dir{
  width: 100%;
  padding: 20px;
  margin-top: 100px;
}
.image-div{
  width: 100%;
  
}
.image-div .img{
  width: 100%;
}
</style>